@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.control {text-align: center;}
.access-code {font-size: 2rem; font-weight: bold;}
.footer {font-size: 0.85em;}
.remove {cursor: pointer;}
.table thead tr th {vertical-align: middle; border-left:1px solid #aaa; border-right:1px solid #aaa}
.table tbody tr td {border:1px solid #aaa;}
.sent {background-color: rgb(217,233,212);}
.unsent {background-color: rgb(244,204,205);}
.link {color: blue; cursor: pointer;}
.link:hover {color: dodgerblue;}
.edit-name {cursor: pointer;}

.computer-list-waiting {font-style: italic;}

